import CompanyContactForm from '../components/contact/companyForm';
import ContactForm from '../components/contact/contactForm';
import FadeInContainer from '../components/shared/sectionAnimation';
import { graphql } from 'gatsby';
import { PageContext } from '../pageContext';
import Seo from '../components/Seo';
import SiteLayout from '../components/layout/layout';
import styled from '@emotion/styled';
import { theme } from '../components/shared/theme';

import { Col, Collapse, Row } from 'antd';
import { H1, H2, TextRegular, TextSmall, TextXsmall } from '../components/shared/typography';
import { IContactPage, IFAQ } from '../components/shared/contentfu.interface';
import React, { Suspense } from 'react';

const StyledImg = styled.img`
  margin: auto;
  margin-bottom: 2rem;
  min-height: 250px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const NavImg = styled.img`
  margin: auto;
  height: 400px;
  @media (max-width: 700px) {
    height: unset;
    width: 100%;
  }
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    background:  ${theme.colors.greyLight.grey5};
    font-size: 1.125rem;
    border-bottom: 1px solid lightgray;
    .anticon {
      font-size: 1.125rem !important;
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      background: ${theme.colors.brandLight.primary};
      color: white;
    }
  }
`;

interface Props {
  data: {
    allContentfulContactPage: {
      nodes: IContactPage[];
    }
    allContentfulFaqPage: {
      nodes: IFAQ[];
    }
  };
  pageContext: PageContext;
}

const ContactPage: React.FC<Props> = ({ data, pageContext }: Props) => {
  const content = data.allContentfulContactPage.nodes[0];
  const FAQContent = data.allContentfulFaqPage.nodes[0];
  const Map = React.lazy(() => import('../components/shared/map/contactMap'));
  const isSSR = typeof window === 'undefined';

  return (
    <SiteLayout
      locale={pageContext.locale}
      hasHeader={false}
      componentName={pageContext.componentName}
    >
      <Seo
        title="Kontakt"
        description="Bestil tid til et corona tjek. Privatcoronatest kontakt informationer samt bestillings muligheder. Bestil en coronatest tid allerede idag. Vi opererer i hele landet alle ugens dage. Ved akutte forespørgsler anbefaler vi de kontakter os direkte på telefon: +45 30633193"
        keywords={[
          'Book test',
          'bestil tid',
          'corona test',
          'kontakt info',
          'Corona test',
          'privatcoronatest',
          'privat corona test',
          'bestil corona test',
          'Danmark',
          'dk',
        ]}
        image={content.pageImg.file.url}
        pathname="contact"
      />
      <Row style={{ background: 'white', padding: '3rem 0', }}>
      <Col xs={{span: 22, order: 1, offset: 1}} md={{span: 12, order: 2, offset: 1}} lg={{span: 8, order: 2, offset: 1}}>
        <H1 style={{color: theme.colors.brandLight.primary, textAlign: 'left'}}>{pageContext.locale === 'da-DK' ? 'Kontakt os' : 'Contact us'}</H1>
          <FadeInContainer transition="XL">
            <h2>{content.company}</h2>
            <p>{content.address}</p>
            <p>{content.phone}</p>
            <p>{content.mail}</p>
            <ContactForm
                    locale={pageContext.locale}
                    privateForm={true}
                  />
          </FadeInContainer>
        </Col>
        <Col xs={{span: 22, order: 2, offset: 1}} md={{span: 8, order: 1, offset: 1}} lg={{span: 13, order: 1, offset: 1}}>
          {!isSSR && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Map />
                    </Suspense>
                  )}
        </Col>
      </Row>
      <Row style={{ marginTop: '4rem', marginBottom: '4rem' }} gutter={[0,40]}>
        <Col span={22} offset={1}>
          <StyledImg
            src={`${content.ruteStep[content.ruteStep.length - 1].img.file.url}`}
            alt={content.ruteStep[content.ruteStep.length - 1].img.file.fileName}
          />
        </Col>
      </Row>
      <Row style={{ padding: '3rem 0', marginBottom: '4rem', }}>
          <H1>{FAQContent.title}</H1>
        <Col xs={{ span: 24 }} md={{ span: 22, offset: 1 }}>
          <StyledCollapse
            bordered={false}
            expandIconPosition="right"
            style={{ background: 'white' }}
          >
            {FAQContent.questions &&
              FAQContent.questions.map((q, key) => {
                return (
                  <Collapse.Panel style={{background: theme.colors.greyLight.grey5}} header={q.question.question} key={key}>
                    <p>{q.answer.answer}</p>
                  </Collapse.Panel>
                );
              })}
            <Collapse.Panel
              header={
                pageContext.locale === 'da-DK' ? 'Rutevejledning' : 'Directions'
              }
              key="rute"
            >
              <H2 style={{textAlign:'center'}}>{content.ruteHead}</H2>
              <TextRegular style={{textAlign:'center'}}>{content.ruteDescription.ruteDescription}</TextRegular>
              {content &&
                content.ruteStep.map((s, i) => (
                  <Col span={22} offset={1} key={i}>
                    <Row justify="center">
                      <NavImg
                        src={`${s.img.file.url}`}
                        alt={s.img.file.fileName}
                      />
                    </Row>
                    <Row justify="center">
                      <TextSmall>{s.description.description}</TextSmall>
                    </Row>
                  </Col>
                ))}
            </Collapse.Panel>
          </StyledCollapse>
        </Col>
      </Row>
    </SiteLayout>
  );
};
export default ContactPage;

export const query = graphql`
  query ContactQuery($locale: String) {
    allContentfulContactPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        address
        company
        companyText {
          companyText
        }
        mail
        phone
        pageImg {
          file {
            fileName
            url
          }
        }
        ruteHead
        ruteDescription {
          ruteDescription
        }
        ruteStep {
          description {
            description
          }
          img {
            file {
              fileName
              url
            }
          }
        }
      }
    }
    allContentfulFaqPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        questions {
          question {
            question
          }
          answer {
            answer
          }
        }
        title
      }
    }
  }
`;
